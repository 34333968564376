@import '../../../assets/sass/variables.scss';

.button {
  height: 56px;
  width: 100%;
  max-width: 365px;
  background-color: $orange;
  color: $white;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  padding: 0;
  text-shadow: rgb(0 0 0 / 30%) 1px 1px 1px;

  &:hover {
    background-color: $orange08;
  }

  &:focus,
  &:active {
    outline: none;
  }

  &:disabled {
    background-color: $gray3;
    cursor: default;
  }
}

.button__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

:focus > .button__inner {
  outline: -webkit-focus-ring-color auto 5px;
}

.button:focus,
.button__inner:focus {
  outline: none;
}
