.z-publications-row{
  max-width: 100%;
  min-height: 35px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 145, 0, 0.5);
  padding: 4px 0;

  &__selected {
    font-weight: bold;
    background: #ffffff;
  }
}

.z-publications-row__item{
  flex: 1;
  padding-left: 0.5rem;
  padding-top:  0.5rem;
  padding-bottom: .25rem;
  word-wrap: break-word;
  
  &__selected {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
  }
}
