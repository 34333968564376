@import '../../assets/sass/variables.scss';

.label {
  text-align: left;
  display: inline-block;
  margin-bottom: 7px;
  width: 100%;

  &-italic {
    font-style: italic;
  }
}

.input-wrapper {
  margin-bottom: 28px;
  position: relative;
}

.error {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: $red;
  margin: 0 0 0 2px;
  text-align: left;
}
