@import '../../assets/sass/variables.scss';

.multiselect {
  max-width: 100%;
  flex: 1 1 auto;
  margin-right: 8px;
  width: 100%;

  &__label {
    p {
      color: #000;
      font-size: 18px;
      line-height: 20px;
      font-weight: 400;
    }
  }

  &__control {
    position: relative;
    font-weight: 400;
    line-height: 20px;
    font-size: 18px;
    color: $darkGray;
    border: 1px solid $gray3;
    background: $gray1;
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    height: 50px;
    padding: 0 33px 0 14px;
    cursor: pointer;

    &::before {
      content: '';
      height: 10px;
      width: 20px;
      background: url('../../assets/svg/down-arrow.svg') center no-repeat;
      position: absolute;
      right: 14px;
      top: 20px;
      outline: none;
      pointer-events: none;
    }

    &:focus,
    &:active {
      border: 1px solid #00b7ea;
      outline: none;
    }
  }

  &__typeahead{
    position: relative;
  }

  &__dropdown-button {
    position: absolute;
    right: 0;
    top: 55px;
    bottom: 0;
    margin-right: 14px;
    height: 16px;
    width: 16px;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      background: url('../../assets/svg/down-arrow.svg') center no-repeat;
      height: 16px;
      width: 16px;
    }
  }

  &__dropdown {
    z-index: 200;
    font-weight: 400;
    line-height: 20px;
    font-size: 18px;
    color: $darkGray;
    border: 1px solid $gray3;
    background: $gray1;
    border-radius: 5px;
    width: 100%;
    max-height: 400px;
    overflow-y: scroll;
    padding: 8px 14px 0 14px;
    margin-bottom: 8px;

    &--colapsed {
      display: none;
    }

    &--open {
      display: flex;
      flex-direction: column;
    }
  }
  
  &__item {
    margin-bottom: 20px;

    label {
      margin-left: 8px;
    }
  }

  &__group-label {

    &-control {
      margin: 0 8px;
      cursor: pointer;
    }

    label {
      font-weight: 600;
    }
  }

  &__item-grouped {
    padding-left: 28px;
  }
}

.hidden {
  display: none;
}
