div, span, p, button {
  font-family: 'Montserrat', sans-serif;
};

h1, h2, h3, h4, h5, h6 {
  font-family: 'EB Garamond', serif;
  font-weight: 400;
};

a {
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  color: #073488;
  text-decoration: none;

  &:hover, &:active {
    color: #00B7EA;
  }
}
