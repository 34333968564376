.hero-image{
  margin-bottom: 24px;
}

.cta-box {
  border: 1px solid gray;
  padding: 14px;
}

.add-section {
  display: flex;
  flex-direction: row-reverse;
}
