.z-select {
    &__wrapper {
        margin-bottom: 28px;
        position: relative;
    }

    &__input {
        width: 100%;
        height: 50px;
        background-color: #F5F5F5;
        padding: 0 28px 0 14px;
        text-overflow: ellipsis;
        margin-top: 7px;

        &::placeholder {
            color: #595959;
        }

        &--wrapper {
            position: relative;
            cursor: pointer;
        }

        &--arrow {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 0 14px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &-open > svg {
              transform: rotate(180deg);
            }
        }

        &--invalid {
            border: 1px solid #ee2a23 !important;
        }

        &--valid {
            &:focus, &:active{
                outline: none;
                color: transparent;
                text-shadow: 0 0 0 gray;
                border: 1px solid #00B7EA;
            }
        }
    }

    &__options {
        width: 100%;
        height: 275px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),
                    0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0
                    rgba(0,0,0,.12);
        overflow-y: scroll;
        cursor: pointer;
        position: absolute;
        margin-top: 7px;
        z-index: 1;

        &--selected {
            background-color: #00B7EA;
            color: #FFFFFF;
        }

        &--unselected {
            color: #343434;
        }

        li {
            padding: 14px;
            white-space: pre-wrap;
            word-wrap: normal;

            &:hover, &:focus {
                background-color: rgba(0,183,234,.2);
            }
        }

        a {
            text-decoration: none;
        }

        &:focus{
            display: block;
        }
    }
}

.z-select:focus + ul {
    display: block;
}

.text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

.z-input {
    border: 1px solid #BDBDBD;
    border-radius: 5px;
    box-sizing: border-box;
    appearance: none;
    outline: none;
    font-size: 18px;
}
