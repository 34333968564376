.parent {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-title {
    font-size: 22px;
  }
}

.search {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &::before {
    content: '';
    display: block;
    background: url('../../../assets/logos/plus.svg') center no-repeat;
    height: 18px;
    width: 18px;
    order: 2;
  }

  &--label {
    margin: 28px 0;
    width: 70%;
    order: 1;
  }

  > div:last-child {
    order: 3;
  }
}
