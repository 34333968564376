@import '../../assets/sass/variables.scss';

.select {
  font-weight: 400;
  line-height: 20px;
  font-size: 18px;
  color: $darkGray;
  border: 1px solid $gray3;
  background: $gray1;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding: 0 33px 0 14px;

  &__wrapper {
    position: relative;
    margin: 7px 0;

    &::before {
      content: '';
      height: 10px;
      width: 20px;
      background: url('../../assets/svg/down-arrow.svg') center no-repeat;
      position: absolute;
      right: 14px;
      top: 20px;
      outline: none;
      pointer-events: none;
    }
  }

  &:focus,
  &:active {
    border: 1px solid #00b7ea;
    outline: none;
  }

  &-invalid {
    border: 1px solid #ee2a23 !important;
  }
}
