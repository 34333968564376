.pages-list {
    margin: 2%;

    &__header {
        display: flex;
        align-items: center;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 40px;
        height: 40px; 
        margin: 0 28px;
        background-color: #faa02f; 
        border-color: #ed9300; 
        text-align: center; 
        border-radius: 2px; 
        box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
        cursor: pointer;

        &__inactive {
            cursor: not-allowed;
        }
    }

    &__search-input {
        margin-bottom:25px;
    }
}

.modal {
    &__header {
        padding-bottom: 28px;
        h2 {
            color: #ed9300;
            text-align: center;
            margin: 7px 0;
        }
    
        p {
            margin: 0;
            line-height: 1.5;
        }
    }

    &__inputs {
        margin-bottom: 28px;
        min-height: 270px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
         
        & > * {
            padding: 7px 0;
         }

         & > p {
            margin: 0;
         }
    }

    &__actions {
        display: flex;
        justify-content: center;
    }
}
