@import '../../../../assets/sass/sass_breakpoints.scss';

.gallery {
  margin: 40px 16px;

  @media only screen and (min-width: $tablet) {
    margin: 40px 20px 64px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  @media only screen and (min-width: $desktop) {
    margin: 60px auto 104px;
    max-width: 1280px;
  }

  &__add-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background-color: #FFF;
    font-size: 24px;
    cursor: pointer;
    margin-top: 8px;

    svg {
      margin-left: 20px;
      pointer-events: none;
    }
  }

  &__title {
    width: 66%;
    margin-bottom: 20px;

    &--row {
      width: 100%;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
  }

  &__toggle {
    width: 33%;
  }

  &__item {
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    position: relative;
    width: 418px;
    height: 238px;
    margin-bottom: 4px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media only screen and (min-width: $tablet) {
      margin: 0;
      height: unset;
      aspect-ratio: 1.76;
    }

    @media only screen and (min-width: $desktop) {
      font-size: 20px;
      line-height: 32px;
      padding: 20px;
      color: rgba(255, 255, 255, .85);

      &:hover,
      &:focus {
        .gallery__item--text {
          color: rgba(255, 255, 255, 1);
        }
        .gallery__item--overlay {
          background: rgba(0, 0, 0, 0.5);
        }
        .gallery__item--delete {
          display: block;
        }
      }
    }

    &--image {
      position: absolute;
      inset: 0;
      aspect-ratio: 1.76;
      pointer-events: none;
      max-width: 100%;
      max-height: 100%;
    }

    &--overlay {
      position: absolute;
      inset: 0;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
      pointer-events: none;
    }

    &--text {
      display: flex;
      font-family: 'Open Sans', sans-serif;
      z-index: 1;
      margin: 0;
      pointer-events: none;
    }

    &--icon {
      margin: 5.5px 10px;
      height: 11px;
      width: 6px;

      @media only screen and (min-width: $desktop) {
        height: 13px;
        width: 8px;
        margin: 9.5px 10px;
      }
    }

    &--delete {
      position: absolute;
      bottom: 15px;
      right: 5px;
      display: none;

      svg {
        fill: #FFF;
        pointer-events: none;
      }
    }
  }
}

.delete {
  padding: 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__row {
    display: flex;
  }

  &__button {
    height: 40px;
    width: 120px;
    margin: 0 10px;
  }
}
