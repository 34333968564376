.link{
  &:hover {
    color: unset;
  }

  .text {
    color: rgb(245, 140, 21);
    text-decoration: none;
    margin-bottom: 5px;
    font-size: 20px;
    padding: 0 14px;
  }
}
