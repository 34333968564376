.news {
  margin: 0 auto;
  min-height: 100vh;

  &.desktop {
    max-width: 1280px;
  }

  &.tablet {
    max-width: 768px;
    padding: 0 20px;
  }

  &.mobile {
    max-width: 375px;
    padding: 16px;
  }

  &__title {
    font-family: 'Open Sans', sans-serif;
    margin: 0 0 40px;
    font-size: 28px;
    color: #343434;
    font-weight: 600;
  }

  &__wrapper {
    position: relative;
    padding: 40px 0;
    overflow: hidden;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1280px;
    margin: 0 auto;
  }
}

.snippets {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.filters {
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: baseline;
  width: 760px;
  height: 100px;
  margin-bottom: 10px;

  &-button {
    font-family: 'Open Sans', sans-serif;
    border: 1px solid #D3D3D3;
    background-color: #FFFFFF;
    color: #444444;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    height: 40px;
    border-radius: 5px;
    margin: 0 0 0 20px;
    cursor: pointer;

    &__arrow {
      margin-top: 3px;
      margin-left: 13px;
      width: 2px;
      height: 8px;
      background: #444444;
      transform: rotate(45deg);
      position: relative;

      &::before {
        content: '';
        position: absolute;
        display: block;
        bottom: -3px;
        left: -3px;
        width: 2px;
        height: 8px;
        background: #444444;
        transform: rotate(90deg);
      }
    }
  }

  &__radio {
    margin: 0 0 0 20px;

    &:last-of-type {
      margin-right: 20px;
    }
  }

  &__total {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    margin: 10px 0;
  }
}
