.editor {
  padding: 30px;

  &__row {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    .label {
      margin-right: 10px;
    }

    &:last-of-type {
      justify-content: center;
    }
  }

  &__button {
    height: 40px;
    width: 120px;
    margin: 0 10px;
  }

  &__spinner {
    margin: 60px auto;
    display: block;
  }

  &__image-saving {
    margin: 0 auto;
    display: block;
  }
}
