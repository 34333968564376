@import '../../assets/sass/variables.scss';

.input {
  font-size: 16px;
  font-weight: 400;
  border: 1px solid $gray3;
  border-radius: 5px;
  width: 100%;
  padding: 14px;
  appearance: none;
  outline: none;
  font-size: 18px;
  background-color: $gray1;
  color: $darkGray;
  resize: vertical;

  &::placeholder {
    font-size: 14px;
    line-height: 16px;
    color: $gray4;
    font-weight: 300;
  }

  &:focus,
  &:active {
    border: 1px solid $lightBlue;
    outline: none;
  }

  &:disabled {
    background-color: $gray1;
  }

  &-invalid {
    border: 1px solid $red !important;
  }
}
