@import '../../assets/sass/variables.scss';

.required {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  border: 1px solid rgb(237,147,0);
  background-color: rgba(237,147,0,.2);
  border-radius: 10px;
  min-height: 400px;

  &__column {
    padding: 20px;
    width: 50%;
    height: 100%;

    &:last-of-type {
      border-radius: 0 10px 10px 0;
      border-left: none;
    }
  }
}

.placeholder {
  display: inline-block;
  background-color: black;
}

.modalInputs {
  margin: 8px 14px;
}

.modal-image-wrapper {
  margin-top: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.modal-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
