.selected {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  height: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;

  &__rating {
    font-family: 'Open Sans', sans-serif;
    color: #444444;
    margin-right: 16px;
    font-size: 14px;
    line-height: 20px;

    span {
      font-weight: 600;
    }
  }

  &__clear {
    font-family: 'Open Sans', sans-serif;
    color: #073488;
    cursor: pointer;
    border: none;
    background: transparent;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    height: 20px;
    margin-left: 16px;

    &:hover {
      color: #009BD4;
    }
  }
}
