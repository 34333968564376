@import '../../../assets/sass/sass_breakpoints.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  user-select: none;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;

  &--left, &--right {
    position: absolute;
    z-index: 10;
    bottom: 62px;

    @media only screen and (min-width: $tablet) {
      bottom: 90px;
    }

    &:focus {
      outline: none;
    }
  }

  &--left {
    left: 14px;

    &--overlay {
      display: none;

      @media only screen and (min-width: $tablet) {
        position: absolute;
        width: 150px;
        height: 100px;
        left: 0px;
        bottom: 64px;
        background: linear-gradient(90deg, #000000 15.79%, rgba(0, 0, 0, 0) 100%);
        z-index: 10;
      }
    }
  }

  &--right {
    right: 14px;

    &--overlay {
      display: none;

      @media only screen and (min-width: $tablet) {
        position: absolute;
        width: 150px;
        height: 100px;
        right: 0;
        bottom: 64px;
        background: linear-gradient(90deg, #000000 15.79%, rgba(0, 0, 0, 0) 100%);
        transform: matrix(-1, 0, 0, 1, 0, 0);
        z-index: 10;
      }
    }
  }

  &--content {
    display: flex;
    position: absolute;
    transition: left 300ms;
    height: 100%;

    &--disabled {
      transition: none;
    }

    &--thumbs {
      width: 100%;
      display: flex;
      position: absolute;
      align-items: center;
      bottom: 56px;
      transition: all 300ms;

      @media only screen and (min-width: $tablet) {
        bottom: 64px;
      }
    }
  }

  &--image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: $tablet) {
      max-width: calc(100vw - 128px);
      margin: 0 64px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &--thumbnail {
    width: 60px;
    height: 60px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    cursor: pointer;

    @media only screen and (min-width: $tablet) {
      width: 100px;
      height: 100px;
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }

    &--selected {
      img {
        border: 2px solid #EF940A;
      }
    }
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;

  &--top, &--bottom {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 16px;

    @media only screen and (min-width: $tablet) {
      padding: 20px;
    }
  }

  &--top {
    position: absolute;
    top: 0;
    z-index: 104;
  }

  &--content {
    display: flex;
    height: calc(100vh - 220px);
    position: absolute;
    top: 28px;
    bottom: 0;
    right: 0;
    left: 0;

    @media only screen and (min-width: $tablet) {
      margin: 0;
      padding: 0 64px;
    }
  }

  &--bottom {
    height: 120px;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
  }
  &--bottom-label {
    height: 56px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    @media only screen and (min-width: $tablet) {
      height: 64px;
      padding: 20px;
    }

    p {
      font-family: 'Open Sans';
      color: white;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.close--wrapper, .arrow--wrapper {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #444444;
}

.zoom--wrapper {
  width: 138px;
  height: 48px;
  border-radius: 24px;
  background-color: white;
  color: #444444;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px; 

    &:first-child, &:last-child {
      font-size: 24px;
    }
  }
}

.arrow--wrapper, .close {
  path {
    fill: #444444;
  }
}

:focus > .arrow--wrapper,
:focus > .close--wrapper
:focus > .zoom--wrapper {
  outline: -webkit-focus-ring-color auto 5px;
}

.arrow--wrapper:focus,
.zoom--wrapper:focus {
  outline: none;
}
