$white: #FFFFFF;
$gray1: #f5f5f5;
$gray2: #EFEFEF;
$gray3: #bdbdbd;
$gray4: #898989;
$darkGray2: #444444;
$darkGray: #343434;
$red: #ee2a23;
$orange: #ED9300;
$orange08: rgba(237,147,0,.8);
$yellow: #ffba00;
$green: #36ae46;
$lightBlue: #00B7EA;
$blue: #009BD4;
$darkBlue: #073488;
$tablet: 768px;
$desktop: 1280px;
