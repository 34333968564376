@import '../assets/sass/variables.scss';

.company-search-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
}

.company-wrapper {
  margin-top: 16px;
}

.company-table {
  max-height: calc(100vh - 132px) !important;
}

.company-table-headers {
  & > th {
    font-size: 16px !important;
  }
}

.company-request-row {
  & > td {
    font-size: 0.875em;
  }
}

.dismiss-all-header, .dismiss-all-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dismiss-all-header {
  flex-direction: column;
}

.dismiss-all-cell {
  flex-direction: row;
}

.status-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  margin: 0 0 14px 0;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.search-total {
  position: relative;
  z-index: 100;
  margin: 28px 0;
}

.phone {
  position: relative;
  cursor: pointer;

  &__full {
    display: none;
    background: $white;
    border: 1px solid $gray4;
    border-radius: 5px;
    padding: 10px;
    width: 300px;
    text-align: left;
    white-space: normal;
    position: absolute;
    right: 150px;
    top: 130px;

    &:after {
      content: "";
      position: absolute;
      top: 11px;
      left: -20px;
      margin-top: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent $darkGray transparent transparent;
    }
  }

  &:hover + .phone__full {
    display: block;
  }
}

.wrap-text {
  white-space: normal !important;
}

.center-text {
  text-align: center !important;
}

.bold {
  font-weight: 400;
}

.error {
  font-weight: 18px;
  color: $red;
}

.spinner {
  width: 100%;
  height: calc(100vh - 132px);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  z-index: 10;

  &__load-more {
    display: flex;
    justify-content: center;
    margin: 50px 0;
  }
}

.company__filter-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.company__filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.company__filter {
  &-small {
    max-width: 145px !important;
  }
}

.company__filter-item {
  label {
    font-size: 14px;
  }
}

.company__chips-container {
  position: relative;
  z-index: 20;
}

.underline {
  text-decoration: underline;
}

.publish__tool-tip {
  width: 330px !important;
}
