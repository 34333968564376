.inputs-wrapper {
    border: 1px solid #009BD4;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 28px;
}

.checkbox {
    margin-right: 10px;
}

.page-spinner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    z-index: 20;
}
