.z-select {
  display: flex;
  justify-content: space-between;
  padding: 7px;

  &--select--wrapper {
      width: 50%;
  }

  &--select {
      display: block;
      font-size: 14px;
      font-weight: bold;
      color: #444;
      line-height: 1.5;
      padding: 2px 4px;
      width: 100%;
      height: 200px;
      max-width: 100%;
      box-sizing: border-box;
      margin: 7px;
      border: 1px solid darkgray;
      box-shadow: 0 1px 0 1px rgba(0,0,0,0.1);
      border-radius: 5px;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;

      &--option {
          font-weight: normal;
      }

      &:hover {
          border-color: gray;
      }

      &:focus {
          border-color: gray;
          box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
          color: black;
          outline: none;
      }
  }
}
