.z-chip__container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
}

.z-chip{
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  background: #ff9100;
  border-radius: 4px;
  padding: 5px 8px;
  margin: 2px;

  &__close{
    cursor: pointer;
    margin-left: 5px;
    border: none;
    background: transparent;

    &:hover{
      color: #ffffff;
    }

    &:disabled {
      cursor: default;
      color: initial;
    }
  }
}

