.hero-image{
  margin-bottom: 24px;
}

.hero-thumbnail {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: baseline;
  max-height: 200px;

  p {
     margin-left: 50px;
     width: fit-content;
  }
}

.photo-gallery{
  margin-bottom: 24px;

  &__add-at-index {
    button {
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 185px;
    }
  }
}
