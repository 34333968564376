@import '../../../assets/sass/sass_breakpoints.scss';

.average {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  border-bottom: 1px solid #EDEDED;
  padding: 24px 0;
  width: 100%;

  @media only screen and (min-width: $tablet) {
    border-bottom: none;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    max-width: 160px;
  }

  &__review-count {
    color: #444444;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-top: 8px;
  }

  &__rating {
    color: #444444;
    font-weight: 600;
    font-size: 44px;
    line-height: 40px;
    margin-right: 16px;
    padding: 0 6px;

    @media only screen and (min-width: $tablet) {
      margin-right: 0;
      font-size: 52px;
      line-height: 52px;
      margin-bottom: 10px;
      margin-right: 0;
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media only screen and (min-width: $tablet) {
      align-items: center;
    }
  }

  &__stars {
    height: 16px;
    width: 94px;
  }
}
