.error-text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ee2a23;
    margin-left: 2px;
    margin-top: 7px;
    text-align: left;
  }
