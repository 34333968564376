.editor {
  padding: 20px;

  &__toggle {
    margin-left: auto;
    margin-bottom: 20px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__button {
    width: 100px;
    height: 40px;
    font-weight: 400;
    margin: 0 10px;
  }
}

.error {
  color: #ee2a23;
  margin-bottom: 20px;
  text-align: center;
}
