.editor {
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #FFFFFF;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__date {
    border: none;
    border-bottom: 1px solid #D3D3D3;
    margin-bottom: 20px;

    &-label {
      margin-right: 20px;
    }
  }

  &__toggle {
    margin-left: auto;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__button {
    width: 100px;
    height: 40px;
    font-weight: 400;
    margin: 0 10px;
  }
}

.error {
  color: #ee2a23;
  margin-bottom: 20px;
  text-align: center;
}
