.card {
  box-sizing: border-box;
  padding: 24px;
  margin: 0 0 16px;
  border: 1px solid #D3D3D3;
  height: 520px;
  width: 308px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;

  &:hover,
  &[aria-selected=true] {
    border: 1px solid #444444;
  }

  &:nth-child(4n) {
    margin-right: 0;
  }

  &:nth-last-child(-n+4) {
    margin-bottom: 0;
  }

  &__logo {
    width: 100%;
    height: 140px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;

    img {
      object-fit: contain;
    }
  }

  &__publication {
    margin: 0 0 12px;
    color: #6C6C6C;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-family: inherit;
  }

  &__title {
    margin: 0 0 12px;
    font-size: 16px;
    font-family: inherit;
    line-height: 24px;
    color: #444444;
    font-weight: 600;
  }

  &__teaser {
    margin: 0 0 20px;
    color: #6C6C6C;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    font-family: inherit;
    line-height: 24px;
    max-height: 214px;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;

    strong {
      font-weight: 600;
    }

    &--wrapper {
      background: #FFFFFF;
    }
  }

  &__link {
    display: flex;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    bottom: 24px;
    margin-top: auto;

    &-arrow {
      margin-top: 3px;
      margin-left: 13px;
      width: 2px;
      height: 8px;
      background: #073488;
      transform: rotate(-45deg);
      position: relative;

      &::before {
        content: '';
        position: absolute;
        display: block;
        bottom: -3px;
        left: -3px;
        width: 2px;
        height: 8px;
        background: #073488;
        transform: rotate(90deg);
      }
    }

    &:hover {
      .card__link-arrow {
        background: #00B7EA;

        &:before {
          background: #00B7EA;
        }
      }
    }
  }


  &.tablet {
    height: 496px;
    width: 356px;

    & > .card__teaser {
      -webkit-line-clamp: 6;
    }
  }

  &.mobile {
    height: unset;
    padding: 20px;
    width: 100%;

    & > .card__title {
      font-size: 15px;
      line-height: 20px;
    }

    & > .card__teaser {
      font-size: 14px;
      line-height: 20px;
      -webkit-line-clamp: 6;
    }
  }
}
