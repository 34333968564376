.inputs-wrapper {
  display: flex;
  flex-direction: column;
}

.author-wrapper {
  box-shadow: inset 0px 0px 5px 1px rgba(0, 0, 0, 0.54);
  background-color: #ffffff;
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.input-item-wrapper {
  display: flex;
  align-items: center;

  label {
    margin-right: 14px;
  }
}
