.action-container{
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 78px;
  margin-top: -50px;

  &__message {
    font-size: 20px;
    margin: 7px 0;
  }

  &__save {
    color: 'red';
    margin: 7px 0;
    font-size: 20px;
    text-align: center;
  }
}
