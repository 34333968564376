.radio-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  height: 40px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #D3D3D3;
  font-family: "Open Sans", sans-serif;
  color: #444444;
  margin: 0;

  &__radio {
    margin-left: 8px;
  }
}
