@import '../../assets/sass/sass_breakpoints.scss';
.review {
  &__outer-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    @media only screen and (min-width: $desktop) {
      box-sizing: border-box;
      padding: 0 10px;
    }
  }

  &__inner-wrapper {
    padding: 0 14px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: $desktop;
    width: 100%;
    box-sizing: border-box;
    margin-top: 32px;

    @media only screen and (min-width: $tablet) {
      margin-top: 44px;
    }

    @media only screen and (min-width: $desktop) {
      flex-direction: row;
      margin-top: 42px;
      padding: 0;
    }
  }

  &__left {
    width: 100%;
    margin-bottom: 56px;

    @media only screen and (min-width: $desktop) {
      width: 865px;
    }

    h1 {
      color: #444444;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 12px;

      @media only screen and (min-width: $tablet) {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 20px;
      }

      @media only screen and (min-width: $desktop) {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 40px;
      }
    }
  }

  &__right {
    width: auto;
  }

  &__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
  }

  &__load-more {
    width: 200px;
    height: 40px;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;

    &:hover {
      border-color: #444444;
    }

    p {
      color: #444444;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @media only screen and (min-width: $tablet) {
      flex-direction: row;
      margin-bottom: 24px;
    }

    @media only screen and (min-width: $desktop) {
      align-items: flex-start;
    }

    &--left {
      color: #7C7C7C;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 24px;
      flex: 1;
      border-bottom: 1px solid #EDEDED;
      max-width: 325px;

      @media only screen and (min-width: $tablet) {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }

  &__sort {
    &--wrapper {
      display: flex;
      flex-wrap: wrap-reverse;
      justify-content: center;
      width: 100%;
      margin-bottom: 40px;
      height: 80px;

      @media only screen and (min-width: $tablet) {
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        height: 40px;
      }
    }
  }

  &__title {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #343434;
    padding: 0 12px;
    background: #FFFFFF;

    @media only screen and (min-width: $tablet) {
      font-size: 24px;
      line-height: 36px;
      padding: 0 16px;
    }

    @media only screen and (min-width: $desktop) {
      text-align: left;
      line-height: 40px;
      font-size: 28px;
      padding: 0;
    }
  }
}
