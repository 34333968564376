.footer-section{
  margin-bottom: 32px;
  background: #ffffff;
  padding: 32px 7px;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  position: relative;
  display: flex;
  flex-direction: column;
  border-left: 16px double grey;

  &--header {
    display: flex;
    flex-direction: column;

    &--title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &--add {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &--link-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &--link {
    display: flex;
    flex-direction: row;
    border-left: 16px double grey;
  }
}

.add-section__btn:disabled {
  svg {
    pointer-events: none;
  }
}
