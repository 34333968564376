@import '../../shared/styles/shared.module.scss';

.position {
  width: 100%;
  max-width: 800px;
  box-shadow: 0px 6px 16px 0px #0000001F;
  box-sizing: border-box;
  margin-bottom: 16px;
  padding: 16px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    box-shadow: 0px 6px 30px 0px #00000026;

    & > .fake-link {
      color: #00B7EA !important;
    }
  }

  span {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #7C7C7C;
  }

  .fake-link {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #073488;
  }
}
