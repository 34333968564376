@import '../../assets/sass/variables.scss';

.p {
  margin: 0 0 10px;
  line-height: 24px;
  height: 24px;
}

.company {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid $blue;
  width: 66%;
  min-height: 116px;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;

  &__name {
    width: 100%;
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 10px;
  }
}

.spinner {
  width: 100%;
  height: 110px;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  z-index:10;
}
