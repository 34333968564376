.button {
  max-height: 40px;
  height: 40px;
  max-width: 270px;
  margin: 0 28px;
  background-color: #faa02f;
  border-color: #ed9300;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.admin {
  &__span {
      width: 100%; 
      height: 2px;
      border-bottom: 2px solid rgba(255, 145, 0,0.5); 
      display: block;
      margin-bottom: 7px;
  }
}

.company {
  display: flex;
  justify-content: space-between;
}
