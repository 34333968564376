@import '../assets/sass/variables.scss';

.company {
  margin-top: 24px;
  margin-bottom: 100px;

  &__form {
    margin: 50px 0;
  }

  h1 {
    margin-bottom: 50px;
  }

  &__p {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  &__buttons {
    margin-top: 40px;
    width: 30%;
    display: flex;

    button:last-of-type {
      color: $darkBlue;

      &:hover {
        color: $lightBlue;
      }
    }
  }

  &__info {
    display: flex;
    width: 45%;

    .company__p {
      width: 50%;
      margin-top: 0;
    }
  }
}

.spinner {
  width: 100%;
  height: calc(100vh - 70px);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  z-index: 20;
}

.company-wrapper {
  margin-bottom: 20px;

  &__created {
    margin: 0 0 10px;
  }

  &__form {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    margin-bottom: 10px;

    h1 {
      margin: 0 20px 0 0;
    }
  }

  &__confirmation {
    font-size: 16px;

    &-wrapper {
      width: 320px;
      margin-bottom: 20px;
    }

    &-status {
      text-align: center;
      font-size: 18px1;
    }
  }

  &__submit {
    width: 33%;
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.spinner {
  width: 100%;
  height: calc(100vh - 70px);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  z-index: 20;
}

.label {
  font-size: 16px;
}

.wrapper {
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &--flex {
    display: flex;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.save-modal-wrapper {
  padding: 28px 14px;

  h2, h3 {
    text-align: center;
  }

  ul {
    margin-left: 50px;
    li {
      list-style: url('../assets/svg/x-red.svg');
    }
  }
}

.modal__confirm {
  width: 100%;
  display: flex;
  justify-content: center;
}
