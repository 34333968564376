@import '../../assets/sass/variables.scss';

.button {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background: $darkGray;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 3px rgb(0 0 0 / 24%);
  transition: all .3s cubic-bezier(.25,.8,.25,1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: fixed;
  right: 25px;
  bottom: 25px;

  &::before {
    content: '';
    background: url('../../assets/svg/arrow_up.svg') center no-repeat;
    height: 24px;
    width: 24px;
    display: block;
  }
}
