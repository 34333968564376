.day{
  background: #ffffff;
  padding: 14px;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  position: relative;
  display: flex;
  border-left: 16px double grey;
}

.day-number {
  margin-right: 14px;
  margin-bottom: 28px;
  width: 125px;

  p {
    margin: 7px 0;
  }

  p:last-child {
    border-width: 0 0 1px 0;
    border-color: #bdbdbd;
    border-style: solid;
  }
}

.row {
  display: flex;
  margin-bottom: 28px;
}

.duration-wrapper{
  display: flex;
  flex-direction: column; 
}

.whats-included {
  padding-bottom: 7px;

  p {
    margin: 7px 0;
  }
  .checkbox-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .checkbox-item{
    margin-right: 24px;
    display: flex;
  
    input {
      height: 28px;
      width: 28px;
    }

    label {
      height: 28px;
      padding: 7px 0 7px 7px;
    }
  }
}

.confirmation {
  color: red;

  &__button {
    max-height: 40px;
    height: 40px;
    width: 135px;
    background-color: #faa02f;
    border-color: #ed9300;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    margin: 7px;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  }
}
