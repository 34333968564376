@import '../shared/styles/shared.module.scss';

.modal {
  &__container {
    background: #FFF;
    color: #343434;
    margin: auto;
    box-sizing: border-box;
    min-height: 400px;
    height: 70vh;
    width: 100vw;
    animation: fadeModal 200ms ease-out;
    z-index: 1350;

    @keyframes fadeModal {
      0% { opacity: 0; }
      100% { opacity: 1;}
    }
    
    @media only screen and (min-width: $tablet) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 5px;
      width: 620px;
      height: fit-content;
      box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    }
  }

  &__gray {
    background: #efefef;
  }

  &-overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    animation: fadeOverlay 200ms ease-out;
    z-index: 1300;
      @keyframes fadeOverlay {
        0% { background-color: rgba(0, 0, 0, 0);}
        100% { background-color: rgba(0, 0, 0, 0.6);}
      }
  }
}

.header-detection {
  position: absolute;
  top: 5px;
  left: 0;
}

:focus > .modal-overlay {
  outline: -webkit-focus-ring-color auto 5px;
}

.modal-overlay:focus {
  outline: none;
}
