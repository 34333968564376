.keywords {
  padding: 7px;
  width: 100%;

  &__add {
      display: flex;
      justify-content: flex-start;
  }

  &__wrapper {
    width: 45%;
    border: 1px solid #bdbdbd;
    padding: 14px;
    border-radius: 5px;
  }
}

.button {
  max-width: 150px;
  align-self: center;
  margin-left: 14px;
}
