.hcs__preview-snippets{
    margin-bottom: 20px;
    margin: 0 10px 20px;
    padding: 10px;
    box-shadow: inset 0px 0px 19px 2px rgba(114, 113, 122, 0.93);
}

.text {
    font-family: 'Open Sans', sans-serif;
}

.hcs__delete-snippets{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-width: 2px;
    border-style: outset;
    text-align: center;
    font-size: 16px;
    max-height: 40px;
    background-color: #faa02f;
    border-color: #ed9300;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    margin: 7px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    &:hover {
        color: #00B7EA;
    }
}
