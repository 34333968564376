@import '../../../assets/sass/variables.scss';

.input {
  box-sizing: border-box;
  font-weight: 400;
  text-overflow: ellipsis;
  border: 1px solid $gray3;
  border-radius: 5px;
  width: 100%;
  appearance: none;
  outline: none;
  height: 50px;
  font-size: 18px;
  background-color: $gray1;
  color: $darkGray;
  padding: 0 33px 0 14px;
  margin: 7px 0;

  &::placeholder {
    color: $gray4;
    font-weight: 300;
  }

  &:focus,
  &:active {
    border: 1px solid $lightBlue;
    outline: none;
  }

  &:disabled {
    background-color: $gray1;
  }

  &-invalid {
    border: 1px solid $red !important;
  }
}
