.checkbox-wrapper {
  display: flex;
  flex-direction: row;
}

.checkbox-item {
  display: flex;
  justify-content: space-between;
  padding: 7px 0;
  line-height: 1.3;
  margin-right: 50px;
}

.checkbox {
  margin: 0 7px;
  width: 20px;
  height: 20px;
  border-radius: 0;
}

.type-wrapper {
  box-shadow: inset 0px 0px 5px 1px rgba(0, 0, 0, 0.54);
  background-color: #ffffff;
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.date-wrapper {
  display: flex;
  align-items: center;

  label {
    margin-right: 14px;
  }
}
