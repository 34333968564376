.z-input__wrapper{
  position: relative;

  .z-input__label{
    left: 0;

    &--focused{
      left: 0;
      cursor: text;
      max-width: 66.66%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform-origin: left bottom;
      transform: translate(0, -1.125rem)
    }
  }
}

.z-input{
  border-width: 0 0 1px 0;
  border-color: #bdbdbd;
  border-style: solid;
  background-color: transparent;
  width: 100%;
  
  &:focus{
    outline: none;

    & + label {
      cursor: text;
      max-width: 66.66%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform-origin: left bottom;
      transform: translate(0, -1.125rem);
    }
  }
}

.error {
  color: red;
  font-size: 16px;

  p {
    margin: 7px 0;
  }

  &__subtext {
    color: initial;

    a {
      margin-left: 7px;
    }
  }
}
