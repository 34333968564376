.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loading-text {
    margin-bottom: 0;
  }
}

.usage-message {
  text-align: center;
  margin: 56px 0;

  h2 {
    border: none;
  }

  p {
    font-size: 16px;
    line-height: 20px;
  }
}

.checkbox {
  margin-right: 7px;
  width: 20px;
  height: 20px;
  border-radius: 0;
}

.publish__wrapper {
  overflow: hidden;
}

.publication__comment__wrapper {
  padding: 10px;
}

.publication__significant__wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}
