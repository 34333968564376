.sample-itinerary{
  background: #ffffff;
  padding: 28px 7px;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  position: relative;
  display: flex;
  border-left: 16px double grey;
  justify-content: space-between;

  &--item-wrapper {
    display: flex;
    flex-direction: column;
    padding: 14px 7px 0 7px;

    &__note {
      font-size: 14px;
      text-align: center;
      margin: 0;
    }
  }

  &--left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 15%;

    &--move {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 14px;
    }

    &--current-pos {
      width: 100%;
      padding-bottom: 14px;
      font-size: 18px;
    }

    &--pos {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--button {
      height: 30px;
      width: 175px;
    }
  }

  &--right {
    display: flex;
    flex-direction: column;
  }

  &--title {
    width: 100%;
    display: flex;

    h3 {
      font-weight: normal;
      width: 80%;
      margin: auto 0;
      font-family: 'Montserrat', sans-serif;
    }

    &--button {
      height: 30px;
      width: 110px;
    }
  }

  &--remove {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 10%;
  }

  &--itinerary {
    display: flex;
    flex-direction: row;
  }

  &--company {
    display: flex;
    align-items: center;
    justify-content: space-between;

      a {
        margin-right: 20px;
      }
  }
}
