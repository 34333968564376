@import '../../assets/sass/variables.scss';

.location {
  display: inline-flex;
  justify-content: space-between;
  min-height: 270px;
  background-color:rgba(255, 186, 0, 0.2);
  border: 1px solid $yellow;
  width: 66%;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0 0;

  &__lists {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 250px;
  }
}

.column {
  min-width: 320px;
}
