.item {
  position: relative;

  &__drag-handle {
    background: transparent;
    border: none;
    position: absolute;
    top: 15px;
    right: 0;
    cursor: pointer;
  }
}
