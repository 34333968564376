.login-form {
  box-sizing: border-box;
  padding: 42px 28px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  
  &__sub-text {
    margin-top: 42px;
    margin-bottom: 0;
  }

  &__button {
    display: block;
    margin: 0 auto;
  }
}
