.filters {
  width: 500px;
  height: 100%;
  padding: 20px 20px 100px;
  position: absolute;
  top: 0;
  right: -550px;
  background-color: #FFFFFF;
  box-shadow: 10px 0 5px 10px #D3D3D3;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  overflow: scroll;

  &__title {
    font-family: 'Open Sans', sans-serif;
  }

  &__sources {
    display: flex;
    flex-wrap: wrap;
  }

  &__buttons {
    display: flex;
  }

  &__show {
    animation-name: slide-in;
    animation-timing-function: ease-out;
  }

  &__hide {
    animation-name: slide-out;
    animation-timing-function: ease-in;
  }
}

.filter {
  max-width: 200px;
  height: 80px;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;

  &[aria-selected=true] {
    border: 2px solid #ed7300;
  }

  &:hover {
    border: 2px solid #D3D3D3;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px 20px;

  &__close {
    padding: 0;
    border: 0;
    background: #757575;
    height: 20px;
    width: 2px;
    position: relative;
    transform: rotate(45deg);
    cursor: pointer;

    &:after {
      content: '';
      background: #757575;
      height: 2px;
      width: 20px;
      position: absolute;
      left: -9px;
      top: 9px;
    }

    &--hide {
      transform: rotate(0);
    }
  }

  &__reset {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #D3D3D3;
    background-color: #FFFFFF;
    color: #444444;
    width: 82px;
    height: 40px;
    margin: 0;
  }
}

@keyframes slide-in {
  0% { right: -550px}
  100% { right: 0%}
}

@keyframes slide-out {
  0% { right: 0px}
  100% { right: -550px}
}
