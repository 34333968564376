@import '../../../assets/sass/sass_breakpoints.scss';

.histogram {
  font-family: 'Open Sans', sans-serif;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 32px 12px 0;
  width: 100%;
  box-sizing: border-box;

  @media only screen and (min-width: $tablet) {
    padding: 0;
    width: 260px;
    max-width: 260px;
  }

  &--item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;

    @media only screen and (min-width: $tablet) {
      margin-bottom: 10px;
    }

    @media only screen and (min-width: $desktop) {
      margin-bottom: 4px;
    }

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #5c5c5c;
      width: 52px;
    }
  }

  &__ratings-button {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    background: transparent;
    border: none;
    cursor: pointer;

    &:first-child {
      color: #073488;
    }

    &:hover {
      color: #009BD4;
    }

    &:disabled {
      color: #444444;
    }
  }
}

.progress-bar {
  &--outer {
    display: inline;
    background-color: #EDEDED;
    width: 191px;
    height: 4px;
    margin: 0 20px;

    @media only screen and (min-width: $tablet) {
      width: 124px;
    }
  }
  &--inner {
    background-color: #009BD4;
    height: 100%;
    max-width: 100%;
  }
}
