.meta-tags {
  box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.54);
  background-color: #ffffff;
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;

  &__note {
    color: #ff9100;
    margin: 0;
  }

  .advanced {
    display: flex;
    justify-content: flex-end;
    padding: 7px 0;
    
    &__open {
      justify-content: flex-start;
    }

    .checkbox-wrapper {
      display: flex;
      flex-direction: column;

      .checkbox-item {
        border-bottom: none;
      }

      p {
        margin: 0;
      }
    }
  }
}
