@import '../../assets/sass/variables.scss';

.tooltip {
  position: relative;

  &__icon, &__iconNoBackground {
    display: block;
    height: 24px;
    width: 24px;
    cursor: pointer;
    z-index: 30;
  }

  &__icon {
    background: url('../../assets/svg/question-mark.svg') center no-repeat;
  }

  &__content {
    background: $white;
    border: 1px solid $gray4;
    border-radius: 5px;
    width: 100%;
    max-width: 330px;
    padding: 10px;
    position: absolute;
    top: -55px;
    left: 50px;
    z-index: 50;

    &:after {
      content: "";
      position: absolute;
      top: 65px;
      left: -20px;
      margin-top: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent $gray4 transparent transparent;
    }
  }
}
