.ztable__header-wrapper {
  font-size: 16px;
  max-width: 100%;
  border-width: 0 0 1px 0;
  border-color: #bdbdbd;
  border-style: solid;
  display: flex;
}

.ztable__header-item {
  border-width: 0 0 0 1px;
  border-color: #bdbdbd;
  border-style: solid;
  padding: 4px;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  word-break: break-word;
  font-weight: bold;
  cursor: pointer;
  justify-content: space-between;
}

.ztable__header-actions {
  width: 100px;
}

.ztable__row-wrapper {
  max-width: 100%;
  max-height: 75vh;
  overflow: auto;
}
