@import '../../assets/sass/variables.scss';
.table {
  max-width: 100%;
  width: 100%;
  border-spacing: 0 10px;

  &__row {
    max-width: 100%;
    background-color: $gray2;
    border-radius: 5px;
    height: 80px;
    border: 10px solid $white;

    &:nth-child(odd) {
      background-color: $gray3;
    }

    &__overlay {
      position: absolute;
      left: 0;
      right: 0;
      height: 92px;
      background: rgba(0,0,0,0.7);
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 28px;
        color: white;
      }
    }
  }

  &__head {
    background-color: $white;

    .table__row {
      background-color: $white;
      border: 1px solid $gray4;
    }
  }

  &__cell,
  &__header {
    padding: 0 10px;
    height: 80px;
    font-weight: 300;
    font-size: 16px;
    text-align: left;
    max-width: 250px;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &__header {
    border-top: 1px solid $gray4;
    border-bottom: 1px solid $gray4;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    background: $white;
    position: sticky;
    top: 10px;
    box-shadow: 0px 5px $white, 0 -15px $white;
    outline: none;
    z-index: 10;

    &:first-child {
      border-left: 1px solid $gray4;
    }

    &:last-child {
      border-right: 1px solid $gray4;
    }

    &--sortable {
      cursor: pointer;
      outline: initial;

      &:hover {
        color: $orange;
      }
    }

    &--sort {
      font-size: 24px;
      margin-left: 7px;
      vertical-align: middle;
    }
  }

  &__body {
    overflow: auto;
    width: 100%;
    height: 600px;
  }

  &__cell {
    white-space: nowrap;
  }
}

.popout {
  display: none;
  background: $white;
  border: 1px solid $gray4;
  color: $darkGray;
  border-radius: 5px;
  padding: 10px;
  min-width: 250px;
  max-width: 400px;
  text-align: left;
  white-space: normal;
  position: absolute;
  top: 0;
  left: 250px;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    top: 11px;
    left: -20px;
    margin-top: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent $darkGray transparent transparent;
  }

  &__wrapper {
    position: relative;
  }

  &__truncated {
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover + .popout {
      display: block;
    }
  }
};
