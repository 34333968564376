@import '../shared/styles/shared.module.scss';

.draggable-grid{

  &__wrapper {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }

  &__grid-item {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;

    @media only screen and (min-width: $tablet) {
      width: 50%;
    }

    @media only screen and (min-width: $desktop) {
      width: 33%;
    }
  }

  &__list-item {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
}
