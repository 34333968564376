.stars {
  background: url(/assets/css/star_full_2.svg) left repeat-x;
  background-size: contain;
  width: 100%;
  max-width: 100%;
  height: 100%;

  &__wrapper {
    position: relative;
    height: 20px;
    width: 120px;
    max-width: 120px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background: url(/assets/css/star_zero_2.svg) left repeat-x;
      background-size: contain;
    }
  }

}
