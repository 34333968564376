@import '../../../assets/sass/sass_breakpoints.scss';

.sort {
  width: 100%;
  height: 40px;
  margin: 0;

  @media (hover: hover) {
    &:not(.active):hover > div > input {
      border-color: #7C7C7C;
    }
  }

  & svg > path:first-of-type {
    fill: #444444;
  }

  @media only screen and (min-width: $tablet) {
    width: 208px;
  }

  &__input {
    background: #FFFFFF;
    color: #444444;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px !important;
    font-weight: 600;
    padding-left: 16px;
    height: 40px;
    margin: 0;

    &:focus,
    &:active {
      outline: none;
      background: #F2F2F2;
      border: none;
      color: #444444;
    }

    @media only screen and (min-width: $tablet) {
      width: 200px;
      font-size: 14px !important;
    }
  }

  &__list {
    height: auto !important;
    min-height: 135px;
    overflow: visible;
    border: none;
    border-radius: 4px;

      @media only screen and (min-width: $tablet) {
        width: 100%;
      }
  }

  &__option {
    font-size: 14px;
    color: #7C7C7C;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    height: 44px;
    box-sizing: border-box;
    padding: 12px 16px !important;
    list-style: none;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 15px;
      margin-right: 10px;
    }

    &[aria-selected=true] {
      background-color: #FFFFFF;

      &::before {
        height: 5px;
        width: 12px;
        border-left: 2px solid #7C7C7C;
        border-bottom: 2px solid #7C7C7C;
        transform: rotate(-45deg);
      }
    }

    @media (hover: hover) {
      &:hover {
        background-color: #FAFAFA !important;
        color: #444444;
      }
    }
  }
}
