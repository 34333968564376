.button {
  font-size: 16px;
  max-height: 40px;
  height: 40px;
  max-width: 270px;
  margin: 0 28px;
  background-color: #faa02f;
  border-color: #ed9300;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.breadcrumb__display {
  display: flex;
  flex-direction: column;
  p {
      margin: 14px 14px 14px 0;
  }
  margin-bottom: 14px;
}


.breadcrumbs {
  display: inline-block;
  align-items: center;
  margin: 0;

  &__crumb {
      display: inline;

      &--last {
          color: #898989;
      }
  }

  &__arrow {
      color: #343434;
  }
}

.breadcrumb-wrapper {
  box-shadow: inset 0px 0px 5px 1px rgba(0, 0, 0, 0.54);
  background-color: #ffffff;
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  h3, p {
    margin: 4px;
  }
}
