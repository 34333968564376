.photo-preview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 300px;

  &__photo{
    width: 300px;
    max-width: 300px;
    min-width: 300px;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    margin-right: 10px;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    & > img {
      width: 100%;
      object-fit: scale-down;
    }
  }

  &__input{
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    max-width: calc(90% - 215px);

    &--hero {
      max-width: calc(100% - 300px);
    }
  }

  &__meta{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &--remove {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 10%;

    button {
      height: 30px;
      width: 30px;
      border: none;
      padding: 0;
      background: none;
      cursor: pointer;

      &:disabled {
        cursor: default;

        svg {
          pointer-events: none;
        }
      }
    }
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    p {
      flex: 1 1 auto;
      min-height: 20px;
      padding: 7px;

      &:nth-child(odd) {
        margin-right: 7px;
      }
    }

    &__field {
      border-left: 1px solid rgb(189, 189, 189);
      border-bottom: 1px solid rgb(189, 189, 189);

      &:last-child {
        width: 100%;
      }

      a {
        word-break: break-all;
      }
    }

    &__copy {
      cursor: pointer;
      border-left: none;
      border-bottom: none;
    }
  }

  &-column {
    flex-direction: column;
    align-items: center;

    .photo-preview__photo {
      height: 200px;
      max-height: 200px;
      min-height: 200px;
      margin-bottom: 28px;
    }

    .photo-preview__input {
      max-width: 100%;
      width: 100%;
    }
  }
}
