.itinerary-wrapper, .landing-page-wrapper {
    box-sizing: border-box;
    width: 100%;
    height: calc(100vh - 50px);
    overflow: 'hidden';
}

.itinerary,
.landing-page,
.landing-page-category,
.company-category {
    height: 100%;
    padding-top: 30px;
    overflow: auto;

    &__error-wrapper {
        margin: 14px 28px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding-right: 28px;

        > * {
          flex: 1;
        }

        h2 {
            color: #ff9100;
            margin: 7px 28px;
        }
    }

    &__save {
        color: 'red';
        margin: 7px 0;
        font-size: 20px;
        text-align: center;
    }

    &__actions {
        display: flex;
        justify-content: center;
        margin-bottom: 54px;
    }
}

.preview {
  &-wrapper {
    flex: 0 0 auto;
  }

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    padding: 10px 0;
    border-width: 2px;
    border-style: outset;
    text-align: center;
  }
}
