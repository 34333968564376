.photo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  min-height: 300px;
  margin-top: 20px;

  &__photo{
    width: 120px;
    max-width: 120px;
    min-width: 120px;
    height: 140px;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);

    & > img {
      object-fit: contain;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__input{
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    max-width: calc(90% - 130px);
  }

  &__meta{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__remove {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-left: 10px;
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    p {
      flex: 1 1 auto;
      min-height: 20px;
      padding: 7px;

      &:nth-child(odd) {
        margin-right: 7px;
      }
    }

    &__field {
      border-left: 1px solid rgb(189, 189, 189);
      border-bottom: 1px solid rgb(189, 189, 189);

      &:last-child {
        width: 100%;
      }

      a {
        word-break: break-all;
      }
    }

    &__copy {
      cursor: pointer;
      border-left: none;
      border-bottom: none;
    }
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
