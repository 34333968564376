
.source {
  width: 100%;
  height: 140px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #D3D3D3;

  img {
    object-fit: contain;
  }

  &__button {
    font-family: 'Open Sans', sans-serif;
    border: 1px solid #D3D3D3;
    background-color: #FFFFFF;
    color: #444444;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;

    &:last-of-type {
      margin: 20px 0 0 20px;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
  }
}
