.card {
  box-sizing: border-box;
  padding: 24px;
  margin: 0 16px 16px 0;
  border: 1px solid #D3D3D3;
  height: 600px;
  width: 308px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover,
  &[aria-selected=true] {
    border: 2px solid #444444;
  }

  &:nth-child(4n) {
    margin-right: 0;
  }

  &:nth-last-child(-n+4) {
    margin-bottom: 0;
  }

  &__image {
    width: 100%;
    height: 160px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;

    img {
      object-fit: contain;
    }
  }

  &__name {
    margin: 0 0 12px;
    color: #757575;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }

  &__title {
    margin: 0 0 12px;
    font-size: 16px;
    line-height: 24px;
    max-height: 72px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #444444;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  &__description {
    margin: 0 0 20px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    color: #757575;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    max-height: 214px;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;

    p {
      margin: 0;
      height: 100%;
      overflow: hidden;
    }
  }
}
