.button {
  box-sizing: border-box;
  height: 56px;
  max-height: 56px;
  width: 100%;
  max-width: 365px;
  background-color: #ED9300;
  color: #FFF;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  display: block;
  margin: 0 auto;

  &:hover {
    background-color: rgba(237,147,0,.8);
  }
  
  &:focus {
    outline: none;
  }

  &__disabled {
    background-color: rgba(237,147,0,.8);
    cursor: default;
  }
}

.button__label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

:focus > .button__label {
  outline: -webkit-focus-ring-color auto 5px;
}

.button__label:focus {
  outline: none;
}
