@import '../../../assets/sass/sass_breakpoints.scss';

.destination {
  font-size: 13px;
  line-height: 16px;
  height: 28px;
  display: flex;
  align-items: center;
  margin: 0 24px 8px 0;

  @media only screen and (min-width: $tablet) {
    margin-right: 16px;
  }

  &__flag {
    max-width: 100% !important;
    width: 24px !important;
    height: 15px !important;

    &--wrapper {
      width: 28px;
      height: 28px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:nth-last-of-type(-n+3) {
    margin-bottom: 0;
  }
}

.destinations {
  display: flex;
  flex-wrap: wrap;

  span {
    color: #444444;
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
    cursor: pointer;
  }
}
