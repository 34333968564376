.toggle {
  position: relative;
  display: flex;
  align-items: center;
  width: 130px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 24px;
    width: 40px;

    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }
  }
}

input:checked + .toggle__slider {
  background-color: #ed7300;
}

input:focus + .toggle__slider {
  box-shadow: 0 0 1px #ed7300;
}

input:checked + .toggle__slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
