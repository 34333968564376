.z-table-row{
  max-width: 100%;
  min-height: 35px;
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid rgba(255, 145, 0,0.5);
}

.z-table-row__item{
  flex: 2 2 0;
  padding-left: 1rem;
  padding-top:  1rem;
  padding-bottom: .25rem;
}

.z-table-row__actions {
  padding-left: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
