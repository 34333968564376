.review {
  padding: 40px 0;
  border-top: 1px solid #EDEDED;
  overflow: hidden;

  &:first-of-type {
    padding-top: 0;
    border-top: none;
  }

  &:nth-of-type(3) {
    border-bottom: 1px solid #EDEDED;
  }
}
