@import '../../../../assets/sass/sass_breakpoints.scss';

.photos {
  &__items {
    &--wrapper {
      margin-top: 24px;
      margin-bottom: 20px;
      position: relative;

      @media only screen and (min-width: $tablet) {
        margin-top: 32px;
        margin-bottom: 28px;
      }
    }
  }

  &__item {
    width: 100px;
    height: 100px;
    cursor: pointer;
    pointer-events: auto;
    border-radius: 4px;
    margin-right: 14px;
    box-sizing: border-box;
    border: 1px solid #D3D3D3;
    position: relative;
    display: inline-block;

    @media only screen and (min-width: $tablet) {
      margin-right: 22px;
    }

    @media only screen and (min-width: $desktop) {
      margin-right: 24px;
    }

    &:nth-child(7) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      max-width: 100%;
    }

    &--error {
      background: #343434;
      object-fit: contain;
    }

    &--hidden {
      opacity: 0;
    }

    &--cover {
      background-color: rgba(52, 52, 52, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      position:absolute;
      top: 0;

      &--text {
        color: white;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
}
