.photo-preview{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 300px;
  margin-bottom: 14px;
  &__photo{
    width: 300px;
    max-width: 300px;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    margin-right: 10px; 
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    & > img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }

  &__input{
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    max-width: calc(90% - 215px);

    &--hero {
      max-width: calc(100% - 300px);
    }
  }

  &__meta{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__remove {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 10%;
    margin-left: 10px;

    &-item {
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      border: none;
      background: none;
      padding: 0;

      &:hover {
        border: solid #ed9300 1px;
      }

      &:disabled {
        cursor: default;
        
        &:hover {
          border: none;
        }
        svg {
        pointer-events: none;
        }
      }
    }
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    p {
      flex: 1 1 auto;
      min-height: 20px;
      padding: 7px;
      
      &:nth-child(odd) {
        margin-right: 7px;
      }
    }
    
    &__field {
      border-left: 1px solid rgb(189, 189, 189);
      border-bottom: 1px solid rgb(189, 189, 189);

      &:last-child {
        width: 100%;
      }

      a {
        word-break: break-all;
      }
    }

    &__copy {
      cursor: pointer;
      border-left: none;
      border-bottom: none;
    }
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
