.z-versions-row{
  max-width: 100%;
  min-height: 35px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 145, 0, 0.5);
  padding: 4px 0;

  &__selected {
    font-weight: bold;
    background: #ffffff;
  }
}

.z-versions-row__item{
  flex: 1;
  padding-left: 1rem;
  padding-top:  1rem;
  padding-bottom: .25rem;
  
  &__selected {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
  }
}

.z-versions-row__actions {
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
}

.button {
  max-height: 25px;
  height: 25px;
  margin: 3px;

  &:disabled {
    cursor: default;
  }
}

.checkmark {
  height: 25px;
  width: 25px;
  margin-right: 7px;
}
