.meta-tags {
  box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.54);
  background-color: #ffffff;
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;

  &__note {
    color: #ff9100;
    margin: 0;
  }

  .advanced {
    display: flex;
    padding: 20px 0;
    gap: 20px;
  }
}
