.path-editor {
  display: flex; 
  align-items: baseline; 
  padding: 14px 7px; 
  padding-bottom: 7px;
}

.modal__heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid black; 
  margin-bottom: 7px;

  h2, 
  p {
      margin: 0 0 7px 0;
  }
}

.modal__buttons {
  margin: 14px 0;
  display: flex;
  justify-content: center;
}

.checkbox-item {
  display: flex;
  justify-content: space-between;
  padding: 7px 0;
  border-bottom: 1px solid lightgray;
  line-height: 1.3;

  .delete {
    max-height: 30px;
    border: none;
    cursor: pointer;
  }
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
