.toast-wrapper{
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  left: 20px;
  bottom: 20px;
  z-index: 300;
  box-sizing: border-box;
  padding: 8px 12px;
  min-width: 150px ;
  color: white;
  animation: slideIn 400ms ease-out 1 forwards;
}

.success{
  background-color: #00b321;
}

@keyframes slideIn {
  0% {
    bottom: 0;
    opacity: 0;
  }

  100%{
    bottom: 20px;
    opacity: 1;
  }
}