.sample-article{
  background: #ffffff;
  padding: 28px 7px;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  position: relative;
  display: flex;
  flex-direction: column;
  border-left: 16px double grey;
  justify-content: space-between;

  &--item-wrapper {
    display: flex;
    flex-direction: column;
    padding: 14px 7px 0 7px;

    &__note {
      font-size: 14px;
      text-align: center;
      margin: 0;
    }
  }

  &--left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
    &--move {
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 14px;
    }

    &--current-pos {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 14px;
      font-size: 18px;
    }

    &--pos {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--change {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &--text {
        font-size: 12px;
        color: blue;
        cursor: pointer;
      }
    }
  }

  &--right {
    display: flex;
    flex-direction: column;
  }

  &--title {
    width: 100%;
    display: flex;
    
    h3 {
      font-weight: normal;
      width: 80%;
      margin: auto 0;
      font-family: 'Montserrat', sans-serif;
    }

    &--button {
      height: 30px;
      width: 110px;
    }
  }

  &--image {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &--remove {
    display: flex;
    align-content: center;
    justify-content: center;
    flex: 1;
  }

  &--itinerary {
    display: flex;
    flex-direction: row;
  }
}
