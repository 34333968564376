@import '../../../assets/sass/sass_breakpoints.scss';

.review {
  font-family: 'Open Sans', sans-serif;

  h1, h2, h3 {
    color: #444444;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
  }

  h1 {
    font-size: 18px;
    line-height: 24px;
    margin: 11px 0 22px 0;

    @media only screen and (min-width: $tablet) {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 30px;
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    &--snippet {
      justify-content: space-between;

      @media only screen and (min-width: $tablet) {
        justify-content: flex-start;
      }
    }

    &--item {
      display: flex;
      align-items: center;
      margin-right: 16px;
      margin-bottom: 18px;
      font-family: 'Open Sans';
      font-style: normal;

      span, a {
        font-size: 14px;
        line-height: 20px;
        margin-left: 10px;
      }

      @media only screen and (min-width: $tablet) {
        margin-bottom: 20px;
      }

      &--snippet {
        &:nth-of-type(3) a {
          margin-left: 0;
      }
        @media only screen and (min-width: $tablet) {
          margin-right: 40px;

          &:nth-of-type(3) {
            margin-left: auto;
            margin-right: 0;
            a {
              margin-left: 10px;
            }
          }
        }
      }

      &--name {
        color: #444444;
        font-weight: 600;
      }
      &--date {
        color: #444444;
        font-weight: normal;
      }
      &--link {
        color: #073488;
        font-weight: normal;

        svg {
          path {
            fill: #073488;
          }
        }

        &:hover {
          svg {
            path {
              fill: #00B7EA;
            }
          }
        }
      }
      &--country {
        color: #444444;
        font-size: 13px;
        line-height: 16px;
      }
      &--country-info {
        display: none;
        color: #444444;
        font-size: 14px;
        line-height: 16px;
        margin-right: 16px;
        margin-left: 0 !important;

        @media only screen and (min-width: $tablet) {
          display: inline;
        }
      }
    }
  }

  &__left {
    &--summary-text {
      &--short {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        p {
          display: none;
          margin-bottom: 0 !important;
        }
        p:first-of-type {
          display: block;
        }
      }
      &--read-more {
        cursor: pointer;
        color: #073488;
        margin-top: 8px;
        display: block;

        &:hover {
          color: #00B7EA;
        }
      }
    }

    &--summary-text, h3 {
      color: #7C7C7C;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-top: 8px;
    }

    &--summary-text p {
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &--company-comments {
      background: #FAFAFA;
      box-sizing: border-box;
      padding: 16px;
      margin-top: 20px;
      margin-bottom: 20px;

      @media only screen and (min-width: $tablet) {
        padding: 20px;
      }

      &--title-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 16px;
        h2, h3 {
          display: inline;
          margin: 0 0 0 14px !important;
        }
      }
    }

    &--footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;

      @media only screen and (min-width: $tablet) {
        margin-top: 28px;
      }
    }
  }

  &__copy-link {
    padding: 0 11px;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 40px;
    width: 40px;

    span {
      display: none;
      margin-left: 11px;
      color: #444444;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
    }

    @media (hover: hover) {
      &:hover {
        background: #F2F2F2;
        width: 170px;

        span {
          display: inline;
        }

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    &--copied {
      background: none;
      width: 180px !important;

      span {
        display: inline;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.avatarCircle {
  background-color: #444444;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
  }
}

.snippet-h2 {
  font-size: 18px;
  line-height: 24px;
  margin: 11px 0 30px 0;
}
.snippet-h3 {
  font-size: 16px;
  line-height: 24px;
  margin: 20px 0 8px 0;
}
.non-snippet-h2 {
  font-size: 16px;
  line-height: 24px;
  margin: 20px 0 8px 0;
}
