.radio {
  display: flex;
  align-items: center;

  &-label {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #343434;
    text-align: left;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 20px;
    cursor: pointer;

    &::before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 1px;
      left: 0;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      border: 1px solid #bdbdbd;
      background-color: #FFF;
    }
  }

  &-input {
    position: absolute;
    opacity: 0;
    z-index: 100;

    &:focus + .radio-label:before,
    &:active + .radio-label:before {
      border: 1px solid #00b7ea;
      outline: none;
    }

    &:disabled + .radio-label:before {
      background-color: #f5f5f5;
      border: 1px solid #bdbdbd;
    }

    &:checked + .radio-label::before {
      border: 5px solid #0275FF;
    }
  }

  &-label__invalid:before {
    border: 1px solid red !important;
  }
}
