$mobile: 0px;
$tablet: 768px;
$desktop: 1280px;

.spinner {
    width: 100%; 
    height: 100vh; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    flex-direction: column;
}

.loading {
    width: 100%;
    height: 100vh;
    display: flex; 
    justify-content: center;
    align-items: center;
}

.error {
    color: red;
}

.button {
    font-size: 16px;
    max-height: 40px;
    height: 40px;
    width: 135px;
    background-color: #faa02f;
    border-color: #ed9300;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    margin: 7px;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.button-link {
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
}

.publish__wrapper {
    height: 100%;
    min-height: 330px;
    overflow: scroll;
}

.publish {
    h2 {
        text-align: center;
        border-bottom: 1px solid gray;
    }

    .confirmation {
        color: red;
        font-weight: bold;
        border: none;
    }
}

.validation,
.confirm-path {
    padding: 7px;

    .subtext {
        font-weight: bold;
    }
}

fieldset {
  border: none;
}

.read-only {
  text-align: center;
}

.svg__btn {
  height: 30px;
  min-width: 30px;
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: default;
    
    svg {
      pointer-events: none;
    }
  }
}
