.options-container{
  border: 1px solid rgba(154,156,156,0.8);
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  position: absolute;
  background-color: #ffffff;
  z-index: 5;
  min-width: 300px;
  max-height: 250px;
  overflow: scroll;
}

.options:hover {
  background-color: rgba(154,156,156,0.8);
  color: #ffffff;
  cursor: pointer;
}

.options {
  color: black;
}

.activeOption {
  background-color: rgba(255, 145, 0,0.5);
}
