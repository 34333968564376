.tab-container {
  display: flex;
  background: #ffffff;
}

.page-container {
  border: 1px solid rgba(154,156,156,0.8);
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  padding: 0 12px 12px 12px;
}

.pages {
  margin: 5% 2% 5% 2%;
  background: #f5f5f5;
}

.tab-item {
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  &:hover {
    color: orange;
  }

  &--active {
    background: rgba(154,156,156,0.8);
    border-radius: 4px 4px 0 0;
    color: white;
    padding: 5px 10px 5px 10px;
  }
}
