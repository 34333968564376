.centered {
  display: flex;
  justify-content: center;
  padding: 42px 0;
}

.title {
  margin-bottom: 28px;
}

.unsaved-warning {
  color: red;
  margin-bottom: 28px;
  text-align: center;
}
