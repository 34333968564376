
.load-more {
  font-family: 'Open Sans', sans-serif;
  color: #444444;
  width: 200px;
  height: 40px;
  border: 1px solid #D3D3D3;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    background-color: #F2F2F2;
    border: 1px solid #F2F2F2;
  }

  &:focus,
  &:active {
    outline: none;
  }

  &-inner {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

:focus > .load-more-inner {
  outline: -webkit-focus-ring-color auto 5px;
}

.load-more:focus,
.load-more-inner:focus {
  outline: none;
}
