@import '../../assets/sass/variables.scss';

.search {
  position: relative;
  width: 576px;
  margin-right: 40px;

  &__clear-button {
    position: absolute;
    right: 0;
    top: 23px;
    bottom: 0;
    margin-right: 14px;
    height: 16px;
    width: 16px;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      background: url('../../assets/svg/x.svg') center no-repeat;
      height: 16px;
      width: 16px;
    }

    &:hover {
      &::before {
        background: url('../../assets/svg/x-orange.svg') center no-repeat;
      }
    }
  }
}
