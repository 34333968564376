.career {
  max-width: 1280px;
  margin: 0 auto;
  min-height: 100vh;

  &__title {
    font-family: 'Open Sans', sans-serif;
    margin: 0 0 40px;
  }

  &__wrapper {
    position: relative;
    padding: 40px 0;
    overflow: hidden;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1280px;
    margin: 0 auto;
  }
}

.careers {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.filters {
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 760px;

  &-button {
    font-family: 'Open Sans', sans-serif;
    border: 1px solid #D3D3D3;
    background-color: #FFFFFF;
    color: #444444;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    height: 40px;
    border-radius: 5px;
    margin: 0 0 0 20px;
    cursor: pointer;
  }

  &__total {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    margin: 10px 0 10px 8px;
  }
}
